import React, { Component } from 'react'
import styled from 'styled-components'

const StyledBbxInfoWidget = styled.div`
  margin-bottom: 16px;
`

class BbxInfoWidget extends Component {
  shouldComponentUpdate () {
    return false
  }
  componentDidMount () {
    const s = document.createElement('script')
    s.src = 'https://zpravy.aktualne.cz/widget_code/0017f0d08c6d11efae9c0cc47ab5f122.js'
    s.setAttribute('data-widget-config', JSON.stringify({ insertInto: '#top-banner-container' }))
    document.body.appendChild(s)
  }

  render () {
    return (
      <StyledBbxInfoWidget id="top-banner-container"/>
    )
  }
}

export default BbxInfoWidget
